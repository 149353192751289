import { Suspense } from "react";

import Box from "@mui/material/Box";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useSearchParams } from "react-router";

import { type AccessControlSettingsRootQuery } from "./__generated__/AccessControlSettingsRootQuery.graphql";
import { AccessControlActionBar } from "./AccessControlActionBar";
import { AccessControlSettingsPage } from "./AccessControlSettingsPage";

export function AccessControlSettingsRoot() {
  const [searchParams, setSearchParams] = useSearchParams({});
  const view = searchParams.get("view");
  const tabValue = view || "sso-groups";

  const data = useLazyLoadQuery<AccessControlSettingsRootQuery>(
    graphql`
      query AccessControlSettingsRootQuery {
        ...AccessControlSettingsPage_query
      }
    `,
    {},
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSearchParams({ view: newValue });
  };

  return (
    <AccessControlSettingsPage queryRef={data}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <AccessControlActionBar
          activeTab={tabValue}
          onTabChange={handleTabChange}
        />

        <Suspense fallback={null}>
          {tabValue === "sso-groups" ? (
            <Box className="mt-4">Groups</Box>
          ) : tabValue === "roles" ? (
            <Box className="mt-4">Roles</Box>
          ) : tabValue === "users" ? (
            <Box className="mt-4">Users</Box>
          ) : null}
        </Suspense>
      </Box>
    </AccessControlSettingsPage>
  );
}
